<script setup lang="ts">
import type { HTMLAttributes } from "vue";

import { type BadgeVariants, badgeVariants } from "./variants";

const props = defineProps<{
  variant?: BadgeVariants["variant"];
  color?: BadgeVariants["color"];
  class?: HTMLAttributes["class"];
}>();
</script>

<template>
  <div :class="cn(badgeVariants({ variant, color }), props.class)">
    <slot />
  </div>
</template>
