import { type VariantProps, cva } from "class-variance-authority";

export { default as Badge } from "./index.vue";

export const badgeVariants = cva(
  cn(
    "inline-flex items-center border pt-0.5 pb-[0.09rem] text-xs transition-colors",
    "focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
  ),
  {
    variants: {
      variant: {
        default: "rounded-full px-2 leading-[1]",
        square: "rounded-md px-1.5 leading-[1]"
      },
      color: {
        default: "text-neutral-6 group-hover:border-neutral-3",
        highlighted: "bg-neutral-6 border-neutral-6 text-neutral-1",
        unread: "bg-red-400 !border-red-400 dark:text-neutral-0"
      }
    },
    defaultVariants: {
      variant: "default",
      color: "default"
    }
  }
);

export type BadgeVariants = VariantProps<typeof badgeVariants>;
