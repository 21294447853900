function useGetClaimStatusFilters() {
  const store = useFilteringStore();

  const isMarked = {
    userClaimStatuses: {
      hasMarked: { _eq: true }
    }
  };
  const isNew = {
    _or: [{ userClaimStatuses: { hasRead: { _eq: false } } }, { _not: { userClaimStatuses: {} } }]
  };
  const isCommunity = { internal: { _eq: false } };

  return computed(() => {
    const filters = [
      ...(store.getIsMarked() ? [isMarked] : []),
      ...(store.getIsNew() ? [isNew] : []),
      ...(store.getIsCommunity() ? [isCommunity] : [])
    ];
    return filters.length > 0 ? { _or: filters } : {};
  });
}

function useCategoryFilters() {
  const store = useFilteringStore();

  return computed(() =>
    store.categories.length > 0 ? { claimCategories: { category: { name: { _in: store.categories } } } } : {}
  );
}

export default function useClaimFilterObject() {
  const claimStatusFilters = useGetClaimStatusFilters();
  const categoryFilters = useCategoryFilters();
  return computed(() => ({
    ...claimStatusFilters.value,
    ...categoryFilters.value
  }));
}
