<script lang="ts" setup>
import type { CategoryRaw } from "~/types";

const props = defineProps<{
  claimCategories: CategoryRaw[];
  class?: string;
}>();

const { locale } = useI18n();
const label = computed(() => (locale.value === "de" ? "labelDe" : "labelEn"));
const categories = computed(() =>
  props.claimCategories
    .map((category) => ({ name: category.categoryName, label: category.category[label.value] }))
    .sort((a, b) => a.label.localeCompare(b.label))
);
</script>

<template>
  <ul v-if="categories.length" class="grow flex flex-wrap gap-1">
    <li v-for="category in categories" :key="category.name" class="flex">
      <BasicBadge class="tracking-wide px-2" variant="square">
        {{ category.label }}
      </BasicBadge>
    </li>
  </ul>
</template>
