export default function useSubmissionStatusFilter() {
  const route = useRoute();
  const statusFilter = computed(() => {
    switch (route.params.filter) {
      case "inbox":
        return "submitted";
      case "rejected":
        return "rejected";
      case "observation":
        return "observed";
      case "spam":
        return "spam";
      case "recent":
        return ["accepted"];
      case "completed":
        return ["checked", "published"];
      case "discarded":
        return ["discarded"];
      default:
        return "submitted";
    }
  });
  return statusFilter;
}
